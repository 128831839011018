<template>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
        <div class="col-md-1">
            <button class="btn" type="button" @click="clear()">
                <box-icon name='customize'></box-icon>
            </button>
        </div>

        <div id="filtros" class="col md-12">

            <div class="row">
                <div class="col-md-2">
                    <input type="text" class="form-control me-2" placeholder="Buscar" v-model="filter" />
                </div>
                <div class="col-md-3 input-group" style="width: 250px">
                    <span class="input-group-text" id="filterStart">Desde:</span>
                    <input type="date" class="form-control me-2" placeholder="dd/mm/yyyy" aria-label="start"
                        aria-describedby="filterStart" v-model="filterStart" />

                </div>
                <div class="col-md-3 input-group" style="width: 250px">
                    <span class="input-group-text" id="filterEnd">Hasta:</span>
                    <input type="date" class="form-control me-2" placeholder="dd/mm/yyyy" aria-label="end"
                        aria-describedby="filterEnd" v-model="filterEnd" />
                </div>
                <div class="col-md-2">
                    <select class="form-select me-2" id="statusSelect" v-model="filterStatus" style="width: 180px">
                        <option value="" selected>-- Estatus --</option>
                        <option v-for="state in statusFilter" v-bind:key="state._id" :value="state._id">{{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2" v-if="showNivel">
                    <select class="form-select" id="nivelSelect" v-model="filterNivel" style="width: 180px">
                        <option value="" selected>-- Nivel --</option>
                        <option class="bg-danger" v-for="nivel in nivelFilter" v-bind:key="nivel._id"
                            :value="nivel._id">
                            {{ nivel.name }}
                        </option>
                    </select>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import CompanyService from "../../services/company.service";

export default {
    props: {
        showNivel: Boolean,
        clearFilter: Boolean
    },
    data: () => ({
        filter: "",
        filterStart: "",
        filterEnd: "",
        filterStatus: "",
        filterNivel: "",
        statusFilter: [], // ['En progreso', 'Crítico', 'Holding', 'Cancelado', 'Terminado', 'Cubierto Interno', 'Feedback'],
        nivelFilter: []// ['I. Becario - Practicante', 'II. Staff', 'III. Mando Medio', 'IV. Gerente', 'V. Director', 'VI. Ejecutivo'],

    }),
    mounted() {
        this.getEstatus();
        this.getNiveles();
    },
    watch: {
        clearFilter(newValue) {
            if (newValue) {
                this.clear();
            }
        },
        filter: function () {

            this.$emit('filter', this.filter)
        },
        filterStart: function () {

            this.$emit('filterStart', this.filterStart)
        },
        filterEnd: function () {

            this.$emit('filterEnd', this.filterEnd)
        },
        filterStatus: function () {

            this.$emit('filterStatus', this.filterStatus)
        },
        filterNivel: function () {

            this.$emit('filterNivel', this.filterNivel)
        }
    },
    methods: {

        clear() {
            this.filter = "";
            this.filterStart = "";
            this.filterEnd = "";
            this.filterStatus = "";
            this.filterNivel = "";
        },
        async getNiveles() {
            try {
                const response = await CompanyService.getNiveles();
                const { data } = response.data;
                this.nivelFilter = data;
            } catch (e) {
                console.log(e);
            }
        },
        async getEstatus() {
            try {
                const response = await CompanyService.getEstatus();
                const { data } = response.data;
                this.statusFilter = data;

            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>

<style scoped>
h4 {
    background: orange;
}
</style>